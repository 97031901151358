import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import { IChat, IChatCreate } from '@common/models/Chat/IChat';
import { ApiService } from 'quangular';
import { IDocument } from '@common/models/Document/IDocument';
import { IChat } from '@common/models/Chat/IChat';
import { IChatDocument } from '@common/models/Chat/IChatDocument';
import { IAddDocumentToChat } from '@common/models/Chat/IAddDocumentToChat';
import { SummaryType } from '@common/enums/SummaryType';
import { IDocumentSummary } from '@common/models/Document/IDocumentSummary';
import { IDeltaTable } from '@common/models/Document/IDeltaTable';
import { IAddDoctoChatAPIResp } from '@common/models/Chat/IAddDoctoChatAPIResp';


@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    constructor(private http: HttpClient, private apiService: ApiService) {}

    getAllDocs(params): Observable<IDocument[]> {
        let url=`Document?pageSize=${params.pageSize}`
        if(params.markerId){
            url=`Document?pageSize=${params.pageSize}&markerId=${params.markerId}&loadMore=true`
        }
        if(params.search && params.search.trim()!=''){
            url=`Document?searchText=${params.search.trim()}`
        }
        return this.apiService.get<IDocument[]>(
           url
        );
    }

    uploadFile(params: FormData, forceUpload?: boolean): Observable<IDocument> {
        let url = `Document`;
        if (forceUpload) {
            url = `Document?forceUpload=${forceUpload}`;
        }
        return this.apiService.post(url, params);
    }

    uploadFolder(params: any, path?: any,forceUpload?: boolean): Observable<IDocument[]> {
      let url = `Document/uploadFolder`;
      if (forceUpload) {
          url = `Document/uploadFolder?forceUpload=${forceUpload}`;
      }
      params.forEach((value: FormDataEntryValue, key: string) => {
          console.log('check key value', key, value);
      });
      const options = { headers: new HttpHeaders(), params: { path: path } };
      return this.apiService.post(url, params, options);
  }

    generateSummary(documentId: string, summaryType: SummaryType): Observable<IDocumentSummary>{
        return this.apiService.post(`Document/${documentId}/summary?type=${summaryType}`,{});
     }

    generateDocUpdates(documentId: string): Observable<IDocumentSummary>{
      return this.apiService.put(`Document/${documentId}/documentUpdates`,{});
    }

    updateChatDocuments(
        docs: IChatDocument[],
        chatId: string,
        tables?: IDeltaTable[],
    ): Observable<IAddDoctoChatAPIResp> {
        const payload: IAddDocumentToChat = {
            document: docs,
        };
        if(tables){
          payload.tables = tables
        }
        return this.apiService.post(`Document/chat/${chatId}`, payload);
    }

    getSummary(docId?: string): Observable<IDocumentSummary> {
        return this.apiService.get<IDocumentSummary>(
            `Document/${docId}/summary`,
        );
    }

    downloadFile(docId:string): Observable<Response> {
        return this.apiService.get<Response>(`Document/${docId}/download`);
    }

    deleteDoc(docId: string,documentName:string): Observable<Response> {
        return this.apiService.delete(`Document/${docId}?documentName=${documentName}`);
    }

    retriggerUploadFile(doc_Id:string): Observable<IDocument> {
        let url = `Document/${doc_Id}/retrigger`;
        return this.apiService.put(url,{});
    }

    getTablesFromExcel(doc_Id:string): Observable<any> {
        return this.apiService.get<string[]>(`Document/${doc_Id}/tables`)
    }

    getAllDeltaTables(params): Observable<IDeltaTable[]> {
      let url=`DeltaTable?pageSize=${params.pageSize}`
      if(params.markerId){
          url=`DeltaTable?pageSize=${params.pageSize}&markerId=${params.markerId}&loadMore=true`
      }
      if(params.search && params.search.trim()!=''){
          url=`DeltaTable?searchText=${params.search.trim()}`
      }
      return this.apiService.get<IDeltaTable[]>(
         url
      );
    }

    getUniqueESGTableCategories() :Observable<string[]> {
      return this.apiService.get<string[]>('DeltaTable/ESGtable/unique/categories')
    }

    getListOfESGTablesForCategory(category:string) :Observable<IDeltaTable[]> {
      return this.apiService.get<IDeltaTable[]>(`DeltaTable/ESGtables/category?category=${category}`)
    }
}
