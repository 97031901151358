import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IChatInteraction } from '@common/models/Chat/IChatInteraction';

@Component({
  selector: 'app-code-dialog',
  templateUrl: './code-dialog.component.html',
  styleUrls: ['./code-dialog.component.scss']
})
export class CodeDialogComponent implements OnInit, OnChanges {
  @Input() showdialog: boolean
  @Input() selectedChatInteraction: IChatInteraction
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter()
  @Input() responseIndex: number
  selectedInteractionCodeText: string = ""
  showCopied: boolean = false
  constructor() { }
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedChatInteraction) {
      if (this.selectedChatInteraction && this.selectedChatInteraction['response'] && this.selectedChatInteraction['response'][this.responseIndex]['final_code']){
        console.log('selectedChatInteraction changedd', this.selectedChatInteraction);
        this.selectedInteractionCodeText = this.selectedChatInteraction['response'][this.responseIndex]['final_code']
      }
      console.log('selectedChatInteraction changed', this.selectedChatInteraction);
    }
  }

  copyCodeToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.selectedInteractionCodeText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 1000);
    document.body.removeChild(selBox);
  }

  onClose() {
    this.closeDialog.emit(false)
  }

}
